import { useAppSelector } from 'root/src/client/logic/helpers/useStore';
import { AuthGroup } from 'root/src/server/util/awsClients/authApiClient.types';
import { Platform } from 'root/src/shared/@types/platforms';
function useUserAuthenticationSelector(state) {
    var _a;
    return (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.authentication;
}
export function useUserAuthentication() {
    return useAppSelector(useUserAuthenticationSelector) || {};
}
export function useUserId() {
    var _a;
    return (_a = useAppSelector(useUserAuthenticationSelector)) === null || _a === void 0 ? void 0 : _a.userId;
}
export function useIsAuthenticated() {
    return Boolean(useAppSelector(useUserAuthenticationSelector));
}
function useUserSelector(state) {
    var _a, _b, _c;
    return {
        authentication: (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.authentication,
        userData: (_b = state === null || state === void 0 ? void 0 : state.app) === null || _b === void 0 ? void 0 : _b.userData,
        stepsCompleted: (_c = state === null || state === void 0 ? void 0 : state.app) === null || _c === void 0 ? void 0 : _c.stepsCompleted,
    };
}
export function useUser() {
    return useAppSelector(useUserSelector);
}
export function useIsBrand() {
    const { groups } = useUserAuthentication();
    return (groups === null || groups === void 0 ? void 0 : groups.includes(AuthGroup.Brand)) || (groups === null || groups === void 0 ? void 0 : groups.includes(AuthGroup.Admin));
}
export function useCreatorFollowersCountByPlatform() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { userData } = useUser();
    const youtubeFollowerCount = (_c = (_b = (_a = userData === null || userData === void 0 ? void 0 : userData.creatorData) === null || _a === void 0 ? void 0 : _a.youtubeData) === null || _b === void 0 ? void 0 : _b.followerCount) !== null && _c !== void 0 ? _c : 0;
    const tiktokFollowerCount = (_f = (_e = (_d = userData === null || userData === void 0 ? void 0 : userData.creatorData) === null || _d === void 0 ? void 0 : _d.tiktokData) === null || _e === void 0 ? void 0 : _e.followerCount) !== null && _f !== void 0 ? _f : 0;
    const twitchFollowerCount = (_j = (_h = (_g = userData === null || userData === void 0 ? void 0 : userData.creatorData) === null || _g === void 0 ? void 0 : _g.twitchData) === null || _h === void 0 ? void 0 : _h.followerCount) !== null && _j !== void 0 ? _j : 0;
    return {
        [Platform.YouTube]: youtubeFollowerCount,
        [Platform.TikTok]: tiktokFollowerCount,
        [Platform.Twitch]: twitchFollowerCount,
    };
}
export function useMaxCreatorFollowerCount() {
    const { youtube, tiktok } = useCreatorFollowersCountByPlatform();
    return Math.max(tiktok, youtube);
}
export function useUserCreatorData() {
    var _a, _b, _c, _d, _e, _f;
    const { userData } = useUser();
    return {
        averageCcv: (_a = userData === null || userData === void 0 ? void 0 : userData.creatorData) === null || _a === void 0 ? void 0 : _a.ccv,
        followerCount: (_b = userData === null || userData === void 0 ? void 0 : userData.creatorData) === null || _b === void 0 ? void 0 : _b.followerCount,
        platform: (_c = userData === null || userData === void 0 ? void 0 : userData.creatorData) === null || _c === void 0 ? void 0 : _c.platform,
        tokens: userData === null || userData === void 0 ? void 0 : userData.tokens,
        connectedPlatforms: (_e = (_d = userData === null || userData === void 0 ? void 0 : userData.tokens) === null || _d === void 0 ? void 0 : _d.map(token => token.platform)) !== null && _e !== void 0 ? _e : [],
        creatorPoints: ((_f = userData === null || userData === void 0 ? void 0 : userData.creatorData) === null || _f === void 0 ? void 0 : _f.creatorPoints) || 0,
    };
}
