var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as Tooltip from '@radix-ui/react-tooltip';
import { twMerge } from 'tailwind-merge';
export const BaseTooltip = (props) => {
    const { children, tooltip, hide, delay = 100, triggerClassName, asChild } = props;
    if (hide) {
        return children;
    }
    return (<Tooltip.Provider delayDuration={delay}>
			<Tooltip.Root>
				<Tooltip.Trigger className={triggerClassName} asChild={asChild}>
					{children}
				</Tooltip.Trigger>
				<Tooltip.Portal>
					<Tooltip.Content collisionPadding={5} className="-translate-y-1 z-[1002] motion-safe:data-[state=closed]:animate-hide-tooltip">
						{tooltip}
					</Tooltip.Content>
				</Tooltip.Portal>
			</Tooltip.Root>
		</Tooltip.Provider>);
};
export const BaseTooltipContent = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return <p className={twMerge('text-xs p-2 text-center rounded bg-secondary animate-slide-up motion-reduce:animate-none', className)} {...props}>{children}</p>;
};
